import { Box, Typography } from "@mui/material";
import { useResponsive } from "src/hooks/useResponsive";
import BookADemoButton from "src/components/BookADemoButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Lottie from "lottie-react";
import waveIconAnimation from "src/assets/lotties/wave-icon.json";
import IntroductionVideo from "src/assets/videos/intruduction.mp4";
import VideoCover from "src/assets/images/cover-introduction.jpg";
import "./Hero.css";

const HeroSection = () => {
  const { upMd } = useResponsive();
  const containerMaxWidth = 1212;
  const containerPdX = 24;
  const matches = useMediaQuery(
    `(max-width:${containerMaxWidth + containerPdX - 1}px)`
  );

  return (
    <>
      <Box
        sx={{
          paddingX: { xs: "24px", md: `${matches ? containerPdX : 0}px` },
          maxWidth: `${containerMaxWidth}px`,
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingY: { xs: "24px", md: "60px" },
            gap: "32px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "35%" },
              flex: { xs: "1", md: "unset" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "25px",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Lottie
                animationData={waveIconAnimation}
                loop={false}
                className="lottie-wave-icon"
              />
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Typography variant="h1" sx={{ wordWrap: "break-word" }}>
                  Enterprise-grade
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    wordWrap: "break-word",
                    background: "-webkit-linear-gradient(#D7D7D7, #212121)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Speech & Language
                </Typography>
                <Typography variant="h1" sx={{ wordWrap: "break-word" }}>
                  AI technologies
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: { xs: "20px", md: "18px" }, color: "#84858A" }}
              >
                Cutting-edge speech foundational model for Thai
              </Typography>
              <BookADemoButton
                sx={{
                  width: !upMd ? "100%" : "fit-content",
                  padding: "12px 36px",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ flex: 1, width: "100%" }}>
            <video
              width="100%"
              height="auto"
              muted={false}
              loop={false}
              autoPlay={false}
              playsInline
              controls={true}
              style={{
                borderRadius: "16px",
              }}
              poster={VideoCover}
              src={IntroductionVideo}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HeroSection;

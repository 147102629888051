import { Modal, Box, IconButton, Typography } from "@mui/material";
import { ReactNode } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface Props {
  open: boolean;
  handleClose: () => void;
  icon: string;
  title: string;
  description: string;
  bottom: ReactNode;
}
const ResponseStatusModalTemplate = ({
  open,
  handleClose,
  icon,
  title,
  description,
  bottom,
}: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="booking-success"
      aria-describedby="booking-success"
    >
      <Box
        sx={{
          bgcolor: "primary.main",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "386px",
          height: "318px",
          padding: "32px 24px 32px 24px",
          borderRadius: "16px",
          border: "1px solid",
          borderColor: "secondary.dark",
          outline: "none",
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: "10px", top: "10px" }}
          onClick={handleClose}
        >
          <CloseRoundedIcon sx={{ color: "secondary.dark" }} />
        </IconButton>
        <Box
          sx={{
            alignItems: "center",
            border: "1px none",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            position: "relative",
          }}
        >
          <Box mb="8px" height="80px">
            <img
              alt="CheckDoubleCircleIcon"
              src={icon}
              width={80}
              height={80}
            />
          </Box>
          <Typography
            variant="h3"
            color="white"
            sx={{
              marginTop: "-1px",
              textAlign: "center",
              whiteSpace: "nowrap",
              width: "fit-content",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              alignSelf: "stretch",
              position: "relative",
              textAlign: "center",
              width: '338px'
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box sx={{ mt: "32px", textAlign: "center" }}>{bottom}</Box>
      </Box>
    </Modal>
  );
};

export default ResponseStatusModalTemplate;

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import BookDemoSection from "src/components/BookDemoSection";
import SectionHeader from "src/components/SectionHeader";
import { useCases } from "src/constants/useCases";

const UseCases = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1212px",
          paddingY: { xs: "0", md: "60px" },
          paddingX: { xs: "24px", md: "0" },
          margin: "0 auto",
        }}
      >
        <SectionHeader
          children1={
            <>
              <Typography variant="h2">Harnessing the Power of</Typography>
              <Typography variant="h2">Voice & Language AI</Typography>
              {/* <Typography
                component="span"
                variant="h2"
                sx={{
                  background: "-webkit-linear-gradient(#D7D7D7, #212121)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  wordWrap: "break-word",
                }}
              >
                Voice & Language AI
              </Typography> */}
            </>
          }
          children2={
            <Typography variant="body1" color="text.secondary">
              Discover the array of use cases enabled by our speech & language
              technologies
            </Typography>
          }
        />
      </Box>
      <Container>
        <Grid container spacing="32px" justifyContent="center" mb="60px">
          {useCases.map((card, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              lg={4}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  maxWidth: 390,
                  width: "100%",
                  bgcolor: "transparent",
                  boxShadow: "none",
                  borderRadius: "16px",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: "100%",
                    aspectRatio: "384/270",
                    borderRadius: "16px",
                  }}
                  image={card.image}
                  alt={card.title}
                />
                <CardContent
                  sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
                >
                  <Typography variant="h6">{card.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <BookDemoSection />
    </>
  );
};

export default UseCases;

import { Button, ButtonProps } from "@mui/material";
// import { ROUTE } from "src/constants/router";

interface BookADemoButtonProps extends Omit<ButtonProps, "text"> {
  text?: string;
}

const BookADemoButton = ({ text, ...props }: BookADemoButtonProps) => {
  return (
    // <Button variant="contained" href={ROUTE.REQUEST_DEMO} {...props}>
    //   {text || "Book a Call"}
    // </Button>
    <Button
      variant="contained"
      href="https://form.jotform.com/242112569644457"
      {...props}
    >
      {text || "Book a Call"}
    </Button>
  );
};

export default BookADemoButton;

import ASRCoverImg from "src/assets/images/cover-asr.png"
import TTSCoverImg from "src/assets/images/cover-tts.png"
import LLMCoverImg from "src/assets/images/cover-llm.png"
import ASVCoverImg from "src/assets/images/cover-asv.png"
import EmotionCoverImg from "src/assets/images/cover-emotion.png"

export const demos = [
    {
        title: 'Automatic Speech Recognition (ASR)',
        describe: 'Our proprietary transcription model is capable of converting spoken language into text with top tier accuracy in real-time, and can handles multiple speakers. Seamlessly deploy on-premise, private cloud, or even fit a raspberry pie.',
        image: ASRCoverImg,
        demo: '/'
    },
    {
        title: 'Generative Voice',
        describe: 'Our speech synthesis AI lets you convert any writing to professional audio, fast. Powered by our own advanced deep learning model, you can create your own custom voice, enabling you to personalize your IVR, voice your contents or empower your voice assistant.',
        image: TTSCoverImg,
        demo: '/'
    },
    {
        title: 'Large Language Models (LLMs)',
        describe: 'By integrating our speech AI with LLMs, you can harness the power of intelligent software. This combination empowers you to do multiple downstream tasks, including intent detection, summarization and even VoiceGPT!',
        image: LLMCoverImg,
        demo: '/'
    },
    {
        title: 'Automatic Speaker Verification (ASV)',
        describe: 'Our voice authentication system utilizes voice characteristics to verify the identity of a speaker. It also incorporates an anti-spoofing model to safeguard against deepfake voices. This capability is especially beneficial for ensuring voice KYC compliance.',
        image: ASVCoverImg,
        demo: '/'
    },
    {
        title: 'Emotion Detection',
        describe: 'Our system can accurately identify various speaker emotions, including anger, sadness, frustration, and happiness - by analyzing the composition of speech, including factors like tone, volume, cadence, whimpers, and laughter. We collaborated with mental health MD to developed model that aids in screening patients with depressions.',
        image: EmotionCoverImg,
        demo: '/'
    },
]
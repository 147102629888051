import { Box, Typography } from "@mui/material";
import CompanyPageBg from "src/assets/images/company-page-bg.png";
import CompanyDecorator from "src/assets/images/company-decorator.png";
import AboutUs from "./AboutUs";
import BookDemoSection from "src/components/BookDemoSection";

const Company = () => {
  return (
    <>
      <Box
        pt={{ xs: "24px", md: "0" }}
        paddingX="24px"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          position: "relative",
        }}
      >
        <AboutUs />
        <Box
          sx={{
            maxWidth: "1300px",
            zIndex: "-1",
            marginTop: { xs: "0", md: "-5%" },
          }}
        >
          <Box
            component="img"
            alt="gowajee-company-bg"
            src={CompanyPageBg}
            sx={{
              width: { xs: "100vw", md: "100%" },
              height: "auto",
            }}
          />
        </Box>
        <Box
          sx={{
            maxWidth: "590px",
            margin: "0 0 60px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginTop: "-5%",
          }}
        >
          <img
            src={CompanyDecorator}
            alt="gowajee-company-decorator"
            width="100%"
          />
          <Typography
            sx={{
              color: "#84858A",
              lineHeight: "150%",
            }}
          >
            Our differentiating factor lies in our extensive research and
            development in fundamental voice technology since 2016. With years
            of R&D and access to proprietary datasets, we have built advanced
            voice models specifically for the Thai language. Trust us for
            state-of-the-art voice recognition solutions that offer unmatched
            accuracy and performance.
          </Typography>
          <Typography
            sx={{
              color: "#84858A",
              lineHeight: "150%",
            }}
          >
            Gowajee.ai was founded by Dr. Ekapol, a MIT PhD and professor at
            Chulalongkorn University, Ren, an Oxford-educated serial startup
            founder, and Pongsatorn, former senior developer at SCB10X.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "590px",
            margin: "0 0 60px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Typography variant="h4">Contact Us</Typography>
          <Typography fontWeight={500}>contact@gowajee.ai</Typography>
        </Box>
      </Box>
      <BookDemoSection />
    </>
  );
};

export default Company;

import { Box, Typography } from "@mui/material";
import { GowajeeFullIcon } from "src/assets/svgs";
import { Link } from "react-router-dom";
import { ROUTE } from "src/constants/router";

const TermsAndConditions = () => {
  return (
    <Box
      pt={{ xs: "24px", md: "0" }}
      paddingX="24px"
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto 150px",
        minHeight: "calc(100vh - 64px)",
        position: "relative",
      }}
    >
      <Box mt={10} mb={10}>
        <img src={GowajeeFullIcon} alt="gowajee-logo" height="12px" />
        <Typography variant="h3" fontWeight={"bold"}>
          Terms and Conditions
        </Typography>
      </Box>

      {/* 1 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          1. Acceptance of Terms
        </Typography>
        <Typography mt={2}>
          Welcome to Gowajee.ai ("the Company," "we," "us," or "our"). These
          Terms and Conditions ("Terms") govern your use of the Gowajee.ai
          website ("the Website") and the services offered through the Website.
          By accessing or using the Website and our services, you agree to
          comply with and be bound by these Terms. If you do not agree to these
          Terms, please refrain from using the Website and our services.
        </Typography>
      </Box>

      {/* 2 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          2. Description of Services
        </Typography>
        <Typography mt={2}>
          Gowajee.ai offers AI voice technology solutions, including but not
          limited to Automatic Speech Recognition, Generative Voice, Automatic
          Speaker Verification, Large Language Models, and Voice Emotion
          Detection services ("the Services").
        </Typography>
      </Box>

      {/* 3 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          3. User Eligibility
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>3.1 Age:</b> You must be at least 18 years old to use the Website
            and our Services.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>3.2 Authority:</b> If you are using the Services on behalf of an
            organization, you represent and warrant that you have the authority
            to bind that organization to these Terms.
          </Typography>
        </Box>
      </Box>

      {/* 4 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          4. Use of Services
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>4.1 Prohibited Activities:</b> You agree not to use the Website
            or our Services for any unlawful, unauthorized, or prohibited
            purposes. This includes, but is not limited to, engaging in any
            activity that could disrupt the functionality of the Services or
            compromise the security of the Website.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>4.2 Accuracy of Information:</b> You are responsible for
            providing accurate and up-to-date information when using the
            Services, including when submitting data for processing.
          </Typography>
        </Box>
      </Box>

      {/* 5 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          5. Intellectual Property
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>5.1 Ownership:</b> The Website, Services, and all related
            content, including text, graphics, images, software, and audio, are
            the intellectual property of Gowajee.ai and are protected by
            copyright and other intellectual property laws.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>5.2 Limited License:</b> Gowajee.ai grants you a limited,
            non-exclusive, non-transferable, and revocable license to access and
            use the Website and Services for their intended purposes.
          </Typography>
        </Box>
      </Box>

      {/* 6 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          6. Limitation of Liability
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>6.1 Disclaimer:</b> The Website and Services are provided "as is"
            and "as available." We make no warranties, express or implied,
            regarding the accuracy, reliability, or suitability of the Services
            for your specific needs.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>6.2 Indirect Damages:</b> In no event shall Gowajee.ai be liable
            for any indirect, incidental, special, consequential, or punitive
            damages arising out of or related to your use of the Website or
            Services.
          </Typography>
        </Box>
      </Box>

      {/* 7 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          7. Privacy
        </Typography>
        <Typography mt={2}>
          Your use of the Website and Services is also subject to our Privacy
          Policy, which outlines how we collect, use, and protect your
          information. By using the Website and Services, you consent to our
          data practices as described in the{" "}
          <Link to={ROUTE.PRIVACY_POLICY}>
            <b>Privacy Policy</b>
          </Link>
          .
        </Typography>
      </Box>

      {/* 8 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          8. Changes to Terms
        </Typography>
        <Typography mt={2}>
          We reserve the right to modify these Terms at any time. Updated
          versions will be posted on the Website. Your continued use of the
          Website and Services constitutes your acceptance of the modified
          Terms.
        </Typography>
      </Box>

      {/* 9 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          9. Governing Law
        </Typography>
        <Typography mt={2}>
          These Terms shall be governed by and construed in accordance with the
          laws of Thailand, without regard to its conflict of law
          principles.
        </Typography>
      </Box>

      {/* 10 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          10. Contact Us
        </Typography>
        <Typography mt={2}>
          If you have any questions or concerns about these Terms or our
          Services, please contact us at <b>contact@gowajee.ai</b>.
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;

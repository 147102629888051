import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#F5F5F5",
    },
    primary: {
      main: "#151515",
      light: "#262626",
      contrastText: "#F5F5F5",
    },
    secondary: {
      dark: "#84858A",
      main: "#FFFFFF",
      light: "#F5F5F5",
      contrastText: "#151515",
    },
    text: {
      primary: "#151515",
      secondary: "#84858A",
    },
  },
  typography: {
    htmlFontSize: 16,
    fontWeightMedium: 400,
    fontFamily: "Helvetica",
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "17.09px",
      textTransform: "none",
    },
    h1: {
      fontSize: 42,
      fontWeight: 400,
    },
    h2: {
      fontSize: 36,
      fontWeight: 400,
    },
    h3: {
      fontSize: 24,
      fontWeight: 500,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "12px 36px",
          borderRadius: "24px",
        },
        contained: ({ theme: _theme }) => ({
          boxShadow: "0px 0px 20px 0px #0000001A",
          "&.MuiButton-containedSecondary": {
            ":hover": {
              background: _theme.palette.secondary.light,
              boxShadow: "0px 0px 4px 0px #0000001A inset",
            },
          },
          "&.MuiButton-containedPrimary": {
            ":hover": {
              background: _theme.palette.primary.light,
              boxShadow: "0px 0px 20px 0px #0000001A",
            },
          },
        }),
        containedSecondary: ({ theme: _theme }) => ({
          boxShadow: "0px 0px 20px 0px #0000001A",
          "&.MuiButton-containedSecondary": {
            ":hover": {
              background: _theme.palette.secondary.light,
              boxShadow: "0px 0px 4px 0px #0000001A inset",
            },
          },
          "&.MuiButton-containedPrimary": {
            ":hover": {
              background: _theme.palette.primary.light,
              boxShadow: "0px 0px 20px 0px #0000001A",
            },
          },
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#FFFFFF99",
          boxShadow: "none",
          backdropFilter: "blur(8px)",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: "0 32px",
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: ({ theme: _theme }) => ({
          border: "1px solid transparent",
          color: _theme.palette.primary.contrastText,
          borderRadius: "4px",
          background: _theme.palette.primary.light,
          padding: "8px 16px",
          fontSize: "16px",
          fontWeight: 400,
        }),
        disabled: ({ theme: _theme }) => ({
          // color: _theme.palette.primary.light,
          // background: _theme.palette.primary.light,
        }),
        error: ({ theme: _theme }) => ({
          border: "1px solid red",
        }),
      },
    },
  },
});

export default theme;

import { Box } from "@mui/material";
import RequestDemoForm from "./RequestDemoForm";
import { useEffect, useRef, useState } from "react";
import WaveVideo from "src/assets/videos/wave-800.mp4";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RequestDemo = () => {
  const formContainerRef = useRef(null);
  const [solutionsMenuHeight, setSolutionsMenuHeight] = useState<number>(0);

  useEffect(() => {
    const curr: any = formContainerRef.current;
    if (curr.offsetHeight) {
      setSolutionsMenuHeight(curr.offsetHeight);
    }
  }, [formContainerRef]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
      container={{
        // optional to render inside custom element
        // element: "[required_id_or_htmlelement]",
        parameters: {
          // badge: "[inline|bottomright|bottomleft]", // optional, default undefined
          theme: "dark", // optional, default undefined
        },
      }}
    >
      <Box
        // pt={{ xs: "56px", md: "65px" }}
        bgcolor="#151515"
        color="#F5F5F5"
        sx={{
          minHeight: {
            xs: "calc(100vh - 297px)",
            md: "calc(100vh - 117px)",
          },
        }}
        ref={formContainerRef}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              padding: { xs: "0 24px", md: "52px 24px" },
            }}
          >
            <RequestDemoForm />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "-webkit-box",
                maxHeight: `${solutionsMenuHeight}px`,
              },
            }}
          >
            <video
              muted
              loop
              autoPlay
              playsInline
              controls={false}
              style={{
                // maxHeight: `${solutionsMenuHeight}px`,
                maxWidth: "50vw",
                width: "auto",
                // height: "calc(100vh - 64px)",
                height: `${solutionsMenuHeight}px`,
                objectFit: "cover",
              }}
            >
              <source src={WaveVideo} type="video/mp4" />
            </video>
          </Box>
        </Box>
      </Box>
    </GoogleReCaptchaProvider>
  );
};

export default RequestDemo;

import { Box, BoxProps } from "@mui/material";

const GradientDivider = (props: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        height: "1px",
        width: "100%",
        background: "radial-gradient(at center, #84858A, #F5F5F5)",
        // background:
        // "radial-gradient(55.59% 493.81% at 43.38% 0%, #84858A 0%, rgba(132, 133, 138, 0) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6))",
        ...props.sx,
      }}
    />
  );
};

export default GradientDivider;

import { ROUTE } from "./router";

export const navigates = [
  {
    name: "GCI",
    path: "https://gci.gowajee.ai/",
    isExternal: true,
    isNew: true,
  },
  { name: "Technology", path: ROUTE.TECHNOLOGY },
  { name: "Use Cases", path: ROUTE.USE_CASE },
  { name: "Company", path: ROUTE.COMPANY },
  { name: "Pricing", path: ROUTE.PRICING },
  {
    name: "Documentation",
    path: "https://developers.gowajee.ai/",
    isExternal: true,
  },
];

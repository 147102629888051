import { useLocation, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Homepage from "./pages/Homepage";
import UseCases from "./pages/UseCases";
import { ROUTE } from "./constants/router";
import Technology from "./pages/Technology";
import Company from "./pages/Company";
import Pricing from "./pages/Pricing";
import RequestDemo from "./pages/RequestDemo";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import GoMeetingZoomUsage from "./pages/GoMeetingZoomUsage";

const routes = [
  { path: ROUTE.HOME, element: <Homepage /> },
  { path: ROUTE.USE_CASE, element: <UseCases /> },
  { path: ROUTE.TECHNOLOGY, element: <Technology /> },
  { path: ROUTE.COMPANY, element: <Company /> },
  { path: ROUTE.PRICING, element: <Pricing /> },
  { path: ROUTE.REQUEST_DEMO, element: <RequestDemo /> },
  { path: ROUTE.PRIVACY_POLICY, element: <PrivacyPolicy /> },
  { path: ROUTE.TERMS_AND_CONDITIONS, element: <TermsAndConditions /> },
  // TEMP:
  { path: ROUTE.GOMEETING_ZOOM_USAGE, element: <GoMeetingZoomUsage /> },
];

function App() {
  const { pathname } = useLocation();

  return (
    <>
      <Navbar theme={pathname === ROUTE.REQUEST_DEMO ? "dark" : "light"} />
      <Box height={{ xs: "57px", md: "64px" }} />
      <Routes>
        {routes.map((route) => {
          return (
            <Route key={route.path} path={route.path} element={route.element} />
          );
        })}
      </Routes>
      <Footer />
    </>
  );
}

export default App;

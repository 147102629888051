import { useState } from "react";
import axios from "axios";

const useEmailSender = () => {
  const [loading, setLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState<number | null>(null);

  const sendEmail = async (data: any, reCaptchaToken: string) => {
    setLoading(true);
    setResponseStatus(null);
    const emailSenderUrl =
      process.env.REACT_APP_EMAIL_SENDER_URL || "http://localhost:8080";

    if (!emailSenderUrl) {
      return;
    }
    try {
      const res = await axios.request({
        method: "post",
        url: emailSenderUrl + `?token=${reCaptchaToken}`,
        headers: {
          "Content-Type": "application/json",
        },
        data,
      });
      setLoading(false);
      setResponseStatus(res.status);
      return res.status;
    } catch (err) {
      console.error("Error sending email:", err);
      setLoading(false);
      if (axios.isAxiosError(err) && err.response) {
        setResponseStatus(err.response.status);
        return err.response.status;
      } else {
        setResponseStatus(0);
        return 0;
      }
    }
  };

  return { sendEmail, loading, responseStatus };
};

export default useEmailSender;

import { Box } from "@mui/material";
import HeroSection from "./sections/Hero";
import BookDemoSection from "src/components/BookDemoSection";
import DemoSection from "./sections/Demo";
import EnterpriseGradeSection from "./sections/EnterpriseGrade";
import GCILaunch from "./sections/GCILaunch";

const Homepage = () => {
  return (
    <Box>
      <GCILaunch />
      <HeroSection />
      <DemoSection />
      <EnterpriseGradeSection />
      <BookDemoSection size="large" />
    </Box>
  );
};

export default Homepage;

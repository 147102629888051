import { AppBar } from "@mui/material";
import { useEffect, useState } from "react";
import { useResponsive } from "src/hooks/useResponsive";
import ToolbarDesktop from "./ToolbarDesktop";
import ToolbarMobile from "./ToolbarMobile";
import GradientDivider from "../GradientDivider";

export interface NavbarProps {
  theme?: "dark" | "light";
}

const Navbar = ({ theme = "light" }: NavbarProps) => {
  const [hideNav, setHideNav] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const { upMd } = useResponsive();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const shouldHideNav = prevScrollPos < currentScrollPos && currentScrollPos > 80;
      setPrevScrollPos(currentScrollPos);
      setHideNav(shouldHideNav);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, theme]);

  return (
    <AppBar
      position="fixed"
      sx={{
        ...(hideNav
          ? {
              transform: "translateY(-110%)",
              transition: "transform .5s",
            }
          : {
              transform: "translateY(0)",
              transition: "transform .5s",
            }),
        background: theme === "dark" ? "#151515" : "rgba(255, 255, 255, 0.60)",
        backdropFilter: "blur(12px)",
      }}
    >
      {upMd ? (
        <ToolbarDesktop theme={theme} />
      ) : (
        <ToolbarMobile theme={theme} />
      )}
      <GradientDivider
        sx={
          theme === "dark"
            ? { background: "radial-gradient(at center, #84858A, #151515)" }
            : {}
        }
      />
    </AppBar>
  );
};

export default Navbar;

import type { UseMediaQueryOptions } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

export const useResponsive = (options?: UseMediaQueryOptions) => {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up("lg"), options);
  const upMd = useMediaQuery(theme.breakpoints.up("md"), options);
  const upSm = useMediaQuery(theme.breakpoints.up("sm"), options);

  return {
    upLg,
    upMd,
    upSm,
  };
};

export enum ROUTE {
    HOME = '/',
    USE_CASE = '/use-cases',
    TECHNOLOGY = '/technology',
    COMPANY = '/company',
    PRICING = '/pricing',
    REQUEST_DEMO = '/request-demo',
    PRIVACY_POLICY = '/privacy-policy',
    TERMS_AND_CONDITIONS = '/terms-and-conditions',
    // TEMP:
    GOMEETING_ZOOM_USAGE = '/gomeeting/usage/zoom',
}
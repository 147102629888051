import SalesSurveillance from 'src/assets/images/use-cases/Sales surveillance.png'
import SpeechEnabled from 'src/assets/images/use-cases/Speech-enabled IVR.png'
import MeetingTranscription from 'src/assets/images/use-cases/Meeting Transcription.png'
import CustomVoice from 'src/assets/images/use-cases/Custom voice creation.png'
import ContentCreation from 'src/assets/images/use-cases/Content creation.png'
import VoiceBiometrics from 'src/assets/images/use-cases/Voice biometrics.png'
import SmartVoice from 'src/assets/images/use-cases/Smart voice assistant.png'
import VoiceGPT from 'src/assets/images/use-cases/VoiceGPT.png'
import Outbound from 'src/assets/images/use-cases/Outbound voice-bot.png'
import Emotion from 'src/assets/images/use-cases/Emotion detection.png'
import Robotics from 'src/assets/images/use-cases/Robotics and Smart vehicles.png'
import Assistive from 'src/assets/images/use-cases/Assistive tech.png'

export const useCases = [
  {
    image: SalesSurveillance,
    title: "Sales surveillance",
    description:
      "Monitors sales agent’s legal required terms and condition during calls.",
  },
  {
    image: SpeechEnabled,
    title: "Speech-enabled IVR",
    description:
      "Replace long customer wait time with fast speech-enabled voice system.",
  },
  {
    image: MeetingTranscription,
    title: "Meeting Transcription",
    description:
      "Transcribes meetings conversation for multiple speakers and summarize meeting minutes.",
  },
  {
    image: CustomVoice,
    title: "Custom voice creation",
    description: "Create custom generated voice for personalized voice-line.",
  },
  {
    image: ContentCreation,
    title: "Content creation",
    description:
      "Generate quality spoken audio for contents like audiobooks and reading news article.",
  },
  {
    image: VoiceBiometrics,
    title: "Voice biometrics",
    description:
      "Use your voice as password to verify identity to increase customer’s security.",
  },
  {
    image: SmartVoice,
    title: "Smart voice assistant",
    description:
      "Alexa-like smart assistant with natural sounding voice, supporting Thai languages.",
  },
  {
    image: VoiceGPT,
    title: "VoiceGPT",
    description:
      "Combine voice technology with LLMs to create intelligent conversational bot.",
  },
  {
    image: Outbound,
    title: "Outbound voice-bot",
    description:
      "Generate leads and sales funnel via automated outbound calls.",
  },
  {
    image: Emotion,
    title: "Emotion detection",
    description:
      "Pinpoint customer’s emotional response in calls and generate insightful report for improved customer experience.",
  },
  {
    image: Robotics,
    title: "Robotics and Smart vehicles",
    description:
      "Embed smart voice assistant in automobiles and robots for human-robot interactions.",
  },
  {
    image: Assistive,
    title: "Assistive tech",
    description:
      "Speech-to-text transcription for hearing deficiency user group.",
  },
];

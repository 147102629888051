// NewTag.tsx
import React from 'react';
import styled, { keyframes } from 'styled-components';

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Tag = styled.div`
  display: inline-block;
  padding: 0.25em 0.5em;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  background: linear-gradient(270deg, #103fd3, #76B0F1, #103fd3);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 3s ease infinite;
  font-size: 12px !important;
`;

const NewTag: React.FC = () => <Tag>New</Tag>;

export default NewTag;

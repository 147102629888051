import { Box } from "@mui/material";

const GciLaunch = () => {
  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(90deg,#103FD3,#000)",
        padding: "12px",
        textAlign: "center",
        fontWeight: 500,
        color: "#FFF",
        lineHeight: "16px",
        cursor: "pointer",
        ":hover": {
          color: "#76B0F1",
        },
      }}
      onClick={() => {
        window.open('https://gci.gowajee.ai/', '_blank')
      }}
    >
      ✨ GOWAJEE Launches the Call Intelligence Platform for Contact Centers ✨
      Learn More→
    </Box>
  );
};

export default GciLaunch;

import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { GowajeeFullGrayIcon } from "src/assets/svgs";
import GradientDivider from "src/components/GradientDivider";
import { navigates } from "src/constants/navigates";
import "./index.css";
import { ROUTE } from "src/constants/router";

const Footer = () => {
  return (
    <>
      <GradientDivider
        sx={{ background: "radial-gradient(at center, #84858A, #151515)" }}
      />
      <Box
        sx={{
          display: "flex",
          padding: { xs: "24px", md: "24px 32px" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
          alignSelf: "stretch",
          bgcolor: "primary.main",
          color: "#84858A",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <img
            src={GowajeeFullGrayIcon}
            alt="gowajee-footer-logo"
            height="16px"
            style={{ margin: "0 16px" }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: { xs: "0", md: "24px" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {navigates.map((nav, i) => (
              <Link to={nav.path} key={i} className="footer-text">
                {nav.name}
              </Link>
            ))}
            <Link to={ROUTE.TERMS_AND_CONDITIONS} className="footer-text">
              Terms & Conditions
            </Link>
            <Link to={ROUTE.PRIVACY_POLICY} className="footer-text">
              Privacy Policy
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "16px",
          }}
        >
          <Box className="footer-text" />
          <Box className="footer-text">
            ⓒ 2021 Gowajee.AI All rights reserved
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Footer;

import { useEffect, useRef, useState } from "react";
import {
  Box,
  Input,
  Typography,
  Button,
  Menu,
  MenuProps,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
  CircularProgress,
} from "@mui/material";
import Lottie from "lottie-react";
import waveIconBlackAnimation from "src/assets/lotties/wave-icon-black.json";
import { ExpandMore } from "@mui/icons-material";
import { phoneNumberRegex } from "src/constants/regex";
import useEmailSender from "src/hooks/useEmailSender";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ResponseStatusModal from "../../components/Modal/ResponseStatusModal";
import { forbiddenEmailDomainsSet } from "src/data/forbiddenEmailDomains";
import { PatternFormat } from "react-number-format";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 4,
      marginTop: "8px",
      minWidth: 180,
      color: theme.palette.text.secondary,
      background: theme.palette.primary.light,
      padding: "16px",
    },
  })
);

const CustomHelperText = ({
  text,
  error,
}: {
  text: string;
  error?: boolean;
}) => {
  return error ? (
    <FormHelperText sx={{ ml: 0.25, mt: 0.5 }} error={error}>
      {text}
    </FormHelperText>
  ) : (
    <FormHelperText sx={{ ml: 0.25, mt: 0.5 }}> </FormHelperText>
  );
};

const validationSchema = z.object({
  name: z.string().nonempty("Please enter your name"),
  email: z
    .string()
    .nonempty("Please enter work email")
    .email("Email is invalid")
    // custom, forbid personal email
    .refine((email) => {
      const emailDomain = email.split("@")[1];
      return !forbiddenEmailDomainsSet.has(emailDomain);
    }, "Please enter only work email"),
  phone: z
    .string()
    .nonempty("Please enter phone number")
    .regex(phoneNumberRegex),
  company: z.string().nonempty("Please enter company name"),
});

type FormData = z.infer<typeof validationSchema>;

const RequestDemoForm = () => {
  const { sendEmail, loading, responseStatus } = useEmailSender();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [executeRecaptchaError, setExecuteRecaptchaError] = useState(false);
  const { register, handleSubmit, formState } = useForm<FormData>({
    resolver: zodResolver(validationSchema),
  });

  const [solutions, setSolutions] = useState([
    { selected: false, label: "Gowajee APIs" },
    { selected: false, label: "Speech Analytics" },
    { selected: false, label: "Transcription" },
    { selected: false, label: "Voicebot" },
    { selected: false, label: "Chatbot" },
    { selected: false, label: "Custom Voice" },
    { selected: false, label: "Incident Reporting" },
    { selected: false, label: "Outgoing Calls" },
    { selected: false, label: "Voice Biometrics" },
    { selected: false, label: "Others" },
  ]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [solutionsMenuWidth, setSolutionsMenuWidth] = useState<number>(0);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const solutionsMenuRef = useRef(null);
  useEffect(() => {
    const curr: any = solutionsMenuRef.current;
    if (curr.offsetWidth) {
      setSolutionsMenuWidth(curr.offsetWidth);
    }
  }, []);

  const handleSolutionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tmpSolutions = solutions;
    const index = solutions.findIndex((s) => s.label === event.target.name);
    tmpSolutions[index].selected = event.target.checked;
    setSolutions([...tmpSolutions]);
  };

  const onSubmit = async (data: FormData) => {
    if (loading) return;
    const { name, email, phone, company } = data;
    if (!executeRecaptcha) {
      setExecuteRecaptchaError(true);
      return;
    }

    const reCaptchaToken = await executeRecaptcha("enquiryFormSubmit");
    const selectedSolutions = solutions
      .filter((solution) => solution.selected)
      .map((solution) => solution.label);

    sendEmail(
      {
        name,
        email,
        company,
        phone,
        solutions: selectedSolutions,
      },
      reCaptchaToken
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: { xs: "32px 0", md: "32px 24px" },
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "24px",
        maxWidth: "590px",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: "contents" }}
      >
        <ResponseStatusModal
          responseStatus={executeRecaptchaError ? 402 : responseStatus}
        />
        <Lottie
          animationData={waveIconBlackAnimation}
          style={{
            width: "80px",
            height: "80px",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography variant="h3">
            Experience the Future: Book a Call Today!
          </Typography>
          <Typography color="#84858A">
            Fill in the form the let us know your business needs and discover
            how our company can transform your business.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "24px 0px",
            alignItems: "flex-start",
            alignContent: "flex-start",
            gap: "16px 16px",
            alignSelf: "stretch",
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "24px",
              width: "100%",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography fontWeight={500} fontSize={"14px"} mb="8px">
                Full Name
              </Typography>
              <FormControl error={!!formState.errors.name} fullWidth>
                <Input
                  {...register("name")}
                  placeholder="Enter your fullname"
                  fullWidth
                  error={!!formState.errors.name}
                />
                <CustomHelperText
                  error={!!formState.errors.name}
                  text={formState.errors.name?.message || ""}
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography fontWeight={500} fontSize={"14px"} mb="8px">
                Work Email
              </Typography>
              <FormControl error={!!formState.errors.email} fullWidth>
                <Input
                  {...register("email")}
                  placeholder="work@company.com"
                  fullWidth
                  error={!!formState.errors.email}
                />
                <CustomHelperText
                  error={!!formState.errors.email}
                  text={formState.errors.email?.message || ""}
                />
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "24px",
              width: "100%",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography fontWeight={500} fontSize={"14px"} mb="8px">
                Phone Number
              </Typography>
              <FormControl error={!!formState.errors.phone} fullWidth>
                <Input
                  {...register("phone")}
                  name="phone"
                  placeholder="000-000-0000"
                  fullWidth
                  error={!!formState.errors.phone}
                  inputComponent={PatternFormat as any}
                  inputProps={{
                    format: "###-###-####",
                    mask: "_",
                  }}
                />
                <CustomHelperText
                  error={!!formState.errors.phone}
                  text={formState.errors.phone?.message || ""}
                />
              </FormControl>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography fontWeight={500} fontSize={"14px"} mb="8px">
                Company
              </Typography>
              <FormControl error={!!formState.errors.company} fullWidth>
                <Input
                  {...register("company")}
                  placeholder="Enter your company name"
                  fullWidth
                  error={!!formState.errors.company}
                />
                <CustomHelperText
                  error={!!formState.errors.company}
                  text={formState.errors.company?.message || ""}
                />
              </FormControl>
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Typography fontWeight={500} fontSize={"14px"} mb="8px">
              Interested Solutions
            </Typography>
            <Button
              ref={solutionsMenuRef}
              id="solution-button"
              aria-controls={open ? "solution-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                borderRadius: "4px",
                background: "#262626",
                color: solutions.filter((s) => s.selected).length
                  ? "#F5F5F5"
                  : "#84858A",
                padding: "8px 16px",
                height: "48px",
                justifyContent: "space-between",
                ":hover": {
                  background: "#262626",
                },
              }}
              endIcon={<ExpandMore />}
              fullWidth
            >
              {solutions.filter((s) => s.selected).length
                ? `${solutions
                    .filter((s) => s.selected)
                    .map((s) => s.label)
                    .join(", ")}`
                : "Chatbot, Voicebot, Transcription, etc,."}
            </Button>
            <StyledMenu
              id="solution-Popper"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "solution-button",
              }}
            >
              <Box sx={{ width: `calc(${solutionsMenuWidth}px - 32px)` }}>
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {solutions.map((solution) => (
                    <FormControlLabel
                      key={solution.label}
                      control={
                        <Checkbox
                          sx={{ "&.Mui-checked": { color: "#F5F5F5" } }}
                          name={solution.label}
                          checked={solution.selected}
                          onChange={handleSolutionChange}
                        />
                      }
                      label={solution.label}
                      sx={{
                        width: { xs: "100%", md: "calc(50% - 5px)" },
                        ":hover": {
                          background: "rgba(255, 255, 255, 0.10)",
                          borderRadius: "4px",
                          color: "#84858A",
                        },
                      }}
                    />
                  ))}
                </FormGroup>
              </Box>
            </StyledMenu>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          sx={{ width: { xs: "100%", md: "150px" } }}
        >
          {loading ? (
            <CircularProgress
              sx={{
                color: "black",
                width: "18px !important",
                height: "18px !important",
              }}
            />
          ) : (
            // "Book Now"
            "Book a Call"
          )}
        </Button>
      </Box>
    </Box>
  );
};
export default RequestDemoForm;

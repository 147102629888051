import { CrosshairIcon, FastForwordIcon, ShieldIcon, ZapIcon } from 'src/assets/svgs'

export const performance = [
  {
    title: "Accurate",
    icon: CrosshairIcon,
    describe:
      "Industry-leading performance in Thai transcription, even for multiple speakers.",
  },
  {
    title: "Fast",
    icon: FastForwordIcon,
    describe:
      "Real-time inference with minimal compute requirements, utilizing CPU instead of GPU for efficient processing.",
  },
  {
    title: "Secure",
    icon: ShieldIcon,
    describe:
      "Portable models enable the seamless deployment of on-premise and private cloud applications.",
  },
  {
    title: "Intelligent",
    icon: ZapIcon,
    describe:
      "Highly customizable and seamlessly integrates with LLMs (Language Models), enabling the infusion of intelligence into your applications.",
  },
];

import { Box, Typography } from "@mui/material";
import { demos } from "src/constants/demos";
import SectionHeader from "src/components/SectionHeader";

const DemoSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "1212px",
        paddingY: { xs: "32px", md: "60px" },
        paddingX: { xs: "24px", md: "0" },
        margin: "0 auto",
      }}
    >
      <SectionHeader
        children1={
          <Box>
            <Typography
              component="span"
              variant="h2"
              sx={{
                lineHeight: "43.2px",
                wordWrap: "break-word",
              }}
            >
              Speech AI foundational models
            </Typography>
            {/* <Typography
              component="span"
              variant="h2"
              sx={{
                lineHeight: "43.2px",
                wordWrap: "break-word",
                background: "-webkit-linear-gradient(#D7D7D7, #212121)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              technologies powering
            </Typography>
            <Typography
              component="span"
              variant="h2"
              sx={{
                lineHeight: "43.2px",
                wordWrap: "break-word",
              }}
            >
              {" "}
              your enterprises
            </Typography> */}
          </Box>
        }
        children2={
          <Typography color="text.secondary">
            Our Voice AI technology covers a wide range of industry-specific
            use-cases, including realistic voice generation, transcribing call
            center conversation for compliance, and voice-based KYC for
            customers.
          </Typography>
        }
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "60px",
          margin: { xs: 0, md: "60px 24px" },
        }}
      >
        {demos.map((demo, _key) => (
          <Box
            key={_key}
            sx={{
              maxWidth: "1000px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "24px", md: "32px" },
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <Box
              component="img"
              src={demo.image}
              alt={demo.title}
              sx={{ maxWidth: "384px", height: "auto", width: "100%" }}
            />
            <Box>
              <Box
                sx={{
                  paddingY: "28px",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Typography variant="h3" mb="16px">
                  {demo.title}
                </Typography>
                <Typography variant="body1" color="text.secondary" mb="auto">
                  {demo.describe}
                </Typography>
                {/* <Box sx={{ mt: "auto" }}>
                  <Button
                    startIcon={<PlayArrowOutlinedIcon />}
                    variant="contained"
                    sx={
                      !upMd
                        ? { width: "100%", height: "41px", mt: "24px" }
                        : { mt: "24px" }
                    }
                  >
                    Play Demo
                  </Button>
                </Box> */}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {/* <Established /> */}
    </Box>
  );
};

export default DemoSection;

import {
  Toolbar,
  IconButton,
  SwipeableDrawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  Button,
  Stack,
} from "@mui/material";
import { useState } from "react";
// import { GowajeeMiniIcon, GowajeeMiniWhiteIcon } from "src/assets/svgs";
import { GowajeeFullIcon, GowajeeFillWhiteIcon } from "src/assets/svgs";
import { navigates } from "src/constants/navigates";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import BookADemoButton from "../BookADemoButton";
import GradientDivider from "../GradientDivider";
import { ROUTE } from "src/constants/router";
import { NavbarProps } from ".";
import { Link } from "react-router-dom";
import NewTag from "../NewTag/NewTag";

const ToolbarMobile = ({ theme }: NavbarProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenDrawer(open);
    };

  return (
    <>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderImage:
            "radial-gradient(55.59% 493.81% at 43.38% 0%, #84858A 0%, rgba(132, 133, 138, 0) 100%)",
          zIndex: 1000,
        }}
      >
        <a href={ROUTE.HOME}>
          <img
            src={theme === "dark" ? GowajeeFillWhiteIcon : GowajeeFullIcon}
            className="App-logo"
            alt="logo"
            height="24px"
          />
        </a>
        {!openDrawer ? (
          <IconButton sx={{ padding: 0 }} onClick={toggleDrawer(true)}>
            <MenuRoundedIcon
              sx={{ height: "25px" }}
              color={theme === "dark" ? "secondary" : "primary"}
            />
          </IconButton>
        ) : (
          <IconButton sx={{ padding: 0 }} onClick={toggleDrawer(false)}>
            <CloseRoundedIcon
              sx={{ height: "25px" }}
              color={theme === "dark" ? "secondary" : "primary"}
            />
          </IconButton>
        )}
      </Toolbar>

      <SwipeableDrawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ zIndex: 999 }}
      >
        <Box
          sx={{ width: "100vw" }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box height="72px" />
          <Box sx={{ px: "24px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 0px 20px 0px #0000001A",
                width: "80px",
                height: "80px",
                borderRadius: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 20px 0px #0000001A",
                  width: "61.54px",
                  height: "61.54px",
                  borderRadius: "100%",
                }}
              >
                <MenuRoundedIcon sx={{ height: "38px" }} color="primary" />
              </Box>
            </Box>
            <List>
              <Link to={ROUTE.HOME} replace>
                <ListItem sx={{ p: 0, height: "56px" }}>
                  <ListItemButton sx={{ fontSize: "20px" }}>
                    Home
                  </ListItemButton>
                </ListItem>
              </Link>

              {navigates.map((nav, i) => (
                <Box key={i}>
                  <GradientDivider sx={{ mb: "24px", mt: "-4px" }} />
                  <ListItem sx={{ p: 0, height: "56px" }}>
                    <Link to={nav.path} replace>
                      <ListItemButton sx={{ fontSize: "20px", gap: 1 }}>
                        {nav.name}
                        {nav.isNew && <NewTag />}
                      </ListItemButton>
                    </Link>
                  </ListItem>
                </Box>
              ))}
            </List>
            <Stack sx={{ gap: 2, mt: "24px" }}>
              <Button
                variant="outlined"
                href="https://console.gowajee.ai/"
                target="_blank"
              >
                Console
              </Button>
              <BookADemoButton sx={{ width: "100%", height: "41px" }} />
            </Stack>
          </Box>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ToolbarMobile;

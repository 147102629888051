import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import BookDemoSection from "src/components/BookDemoSection";
import SectionHeader from "src/components/SectionHeader";
import InfoIcon from "@mui/icons-material/Info";
// import { ROUTE } from "src/constants/router";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BaseHeaderGridStyle = {
  borderWidth: "1px 1px 0 1px",
  borderStyle: "solid",
  borderColor: "#84858A",
  padding: "16px",
  textAlign: "center",
  fontWeight: "bold",
};

const BaseBodyGridStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#84858A",
};

const BaseRowGridStyle = {
  borderWidth: "0 0 1px 0",
  borderStyle: "solid",
  borderColor: "#84858A",
};

const BaseRowItemGridStyle = {
  borderWidth: "0 1px 0 0",
  borderStyle: "solid",
  borderColor: "#84858A",
  padding: "16px",
  textAlign: "center",
};

const Pricing = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1212px",
          paddingY: { xs: "0", md: "60px" },
          paddingX: { xs: "24px", md: "0" },
          margin: "0 auto",
        }}
      >
        <SectionHeader
          children1={
            <>
              <Typography variant="h2">Pricing for Gowajee APIs</Typography>
            </>
          }
          children2={
            <Typography variant="body1" color="text.secondary">
              Discover our straightforward, pricing model for accessing the
              powerful Gowajee APIs.
            </Typography>
          }
        />
      </Box>

      <Container>
        <Stack
          sx={{
            width: "100%",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Typography variant="h3">Speech to Text</Typography>
          {/* <Typography variant="body1" color="text.secondary">
            With Gowajee's advanced speech-to-text technology, you can achieve
            high accuracy and efficient processing, ensuring that your
            transcriptions are both reliable and fast.
          </Typography> */}
          <Typography variant="body1" color="text.secondary">
            Our pricing is based on the audio duration, charged per second. This
            means you only pay for the exact length of the audio you transcribe.
            For example, if your audio is 61 seconds long, you will be charged
            for 61 seconds, not 2 minutes.
          </Typography>
          <Typography variant="caption" color="text.secondary" fontSize={14}>
            * Please note that the Streaming API will be charged based on the
            duration of the WebSocket connection, not the audio or speech time.
          </Typography>
        </Stack>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          mb={10}
          mt={5}
          sx={{ background: "#FFF", borderRadius: "10px" }}
        >
          <Grid
            xs={4}
            sx={{
              ...BaseHeaderGridStyle,
              borderRadius: "10px 0 0 0",
              borderRight: 0,
            }}
          >
            Model
          </Grid>
          <Grid xs={4} sx={{ ...BaseHeaderGridStyle, borderRight: 0 }}>
            Price
          </Grid>
          <Grid
            xs={4}
            sx={{
              ...BaseHeaderGridStyle,
              borderRadius: "0 10px 0 0",
            }}
          >
            Enterprise
          </Grid>

          <Grid
            xs={8}
            sx={{
              ...BaseBodyGridStyle,
              borderRight: 0,
              borderRadius: "0 0 0 10px",
            }}
          >
            {/* ========================= Pulse ========================= */}
            <Grid container sx={{ ...BaseRowGridStyle }}>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle }}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      background:
                        "linear-gradient(to bottom, #212121 0%, #2356F6 100%);",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    Pulse
                  </Typography>
                  <Tooltip title="Pulse strikes the best balance between accuracy and speed, making it ideal for applications where both performance and responsiveness are crucial.">
                    <InfoIcon sx={{ width: "18px", color: "#b0b0b0" }} />
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle, border: 0 }}>
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    background:
                      "linear-gradient(to bottom, #212121 0%, #2356F6 100%);",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  $0.00583/min
                </Typography>
              </Grid>
            </Grid>

            {/* ========================= Pulse + Diarization ========================= */}
            <Grid container sx={{ ...BaseRowGridStyle }}>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle }}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography>Pulse + Diarization</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle, border: 0 }}>
                $0.01/min
              </Grid>
            </Grid>

            {/* ========================= Pulse + Streaming ========================= */}
            <Grid container sx={{ ...BaseRowGridStyle }}>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle }}>
                Pulse + Streaming
              </Grid>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle, border: 0 }}>
                $0.0075/min *
              </Grid>
            </Grid>

            {/* ========================= Cosmos ========================= */}
            <Grid container sx={{ ...BaseRowGridStyle }}>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle }}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      wordWrap: "break-word",
                      background:
                        "linear-gradient(to bottom, #212121 0%, #2356F6 100%);",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    Cosmos
                  </Typography>
                  <Tooltip title="Recommended for most use cases. Cosmos is the most accurate model, and the speed is still acceptable.">
                    <InfoIcon sx={{ width: "18px", color: "#b0b0b0" }} />
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle, border: 0 }}>
                <Typography
                  sx={{
                    wordWrap: "break-word",
                    background:
                      "linear-gradient(to bottom, #212121 0%, #2356F6 100%);",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  $0.01/min
                </Typography>
              </Grid>
            </Grid>

            {/* ========================= Cosmos + Diarization ========================= */}
            <Grid container sx={{ ...BaseRowGridStyle }}>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle }}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography>Cosmos + Diarization</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle, border: 0 }}>
                $0.015/min
              </Grid>
            </Grid>

            {/* ========================= Astro ========================= */}
            <Grid container sx={{ ...BaseRowGridStyle }}>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle }}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography>Astro</Typography>
                  <Tooltip title="Astro is a GPU-based model that is optimized for accuracy.">
                    <InfoIcon sx={{ width: "18px", color: "#b0b0b0" }} />
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle, border: 0 }}>
                $0.02083/min
              </Grid>
            </Grid>

            {/* ========================= Astro + Diarization ========================= */}
            <Grid container sx={{ ...BaseRowGridStyle, borderBottom: 0 }}>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle }}>
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography>Astro + Diarization</Typography>
                </Stack>
              </Grid>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle, border: 0 }}>
                $0.025/min
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={4}
            sx={{
              ...BaseBodyGridStyle,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0 0 10px 0",
            }}
          >
            <Link to="https://form.jotform.com/242112569644457" target="_blank">
              <Stack
                sx={{ flexDirection: "row", gap: 1, alignItems: "center" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Contact Sales
                </Typography>
                <ArrowForwardIcon
                  sx={{ width: "15px", display: { xs: "none", md: "unset" } }}
                />
              </Stack>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Container>
        <Stack
          sx={{
            width: "100%",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Typography variant="h3">Text to Speech</Typography>
          <Typography variant="body1" color="text.secondary">
            Gowajee Text-to-Speech API (available soon) will offer seamless
            conversion of written text into natural-sounding speech.
          </Typography>
        </Stack>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          mb={10}
          mt={5}
          sx={{ background: "#FFF" }}
        >
          <Grid
            xs={4}
            sx={{
              ...BaseHeaderGridStyle,
              borderRadius: "10px 0 0 0",
              borderRight: 0,
            }}
          >
            Model
          </Grid>
          <Grid xs={4} sx={{ ...BaseHeaderGridStyle, borderRight: 0 }}>
            Price
          </Grid>
          <Grid
            xs={4}
            sx={{
              ...BaseHeaderGridStyle,
              borderRadius: "0 10px 0 0",
            }}
          >
            Enterprise
          </Grid>
          <Grid
            xs={8}
            sx={{
              ...BaseBodyGridStyle,
              borderRight: 0,
              borderRadius: "0 0 0 10px",
            }}
          >
            <Grid container sx={{ ...BaseRowGridStyle, borderBottom: 0 }}>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle }}>
                TTS (Available Soon)
              </Grid>
              <Grid xs={6} sx={{ ...BaseRowItemGridStyle, border: 0 }}>
                Soon
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={4}
            sx={{
              ...BaseBodyGridStyle,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "0 0 10px 0",
            }}
          >
            <Link to="https://form.jotform.com/242112569644457" target="_blank">
              <Stack
                sx={{ flexDirection: "row", gap: 1, alignItems: "center" }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Contact Sales
                </Typography>
                <ArrowForwardIcon
                  sx={{ width: "15px", display: { xs: "none", md: "unset" } }}
                />
              </Stack>
            </Link>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ my: 10 }}>
        <Stack sx={{ width: "100%", alignItems: "center" }}>
          <Button
            variant="outlined"
            href="https://developers.gowajee.ai/"
            target="_blank"
            sx={{ width: "fit-content" }}
          >
            Read API Documentation
          </Button>
        </Stack>
      </Container>

      <BookDemoSection />
    </>
  );
};

export default Pricing;

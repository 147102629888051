import { useEffect, useState } from "react";
import ResponseStatusModalTemplate from "./Template";
import {
  CheckDoubleCircleIcon,
  ShieldDoubleCircleIcon,
  WarningDoubleCircleIcon,
} from "src/assets/svgs";
import { Button } from "@mui/material";
import { ROUTE } from "src/constants/router";

interface Props {
  responseStatus: number | null;
}
const ResponseStatusModal = ({ responseStatus }: Props) => {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [recaptchaErrorModalOpen, setRecaptchaErrorModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  useEffect(() => {
    // successfully
    if (responseStatus === 200) setSuccessModalOpen(true);
    // Execute recaptcha not yet available
    if (responseStatus === 402) setRecaptchaErrorModalOpen(true);
    // reCAPTCHA verification failed
    if (responseStatus === 403) setRecaptchaErrorModalOpen(true);
    // reCAPTCHA token is missing
    if (responseStatus === 400) setErrorModalOpen(true);
    // something went wrong
    if (responseStatus === 500) setErrorModalOpen(true);

    // default value
    if (responseStatus === null) {
      setSuccessModalOpen(false);
      setRecaptchaErrorModalOpen(false);
      setErrorModalOpen(false);
    }

    return () => {
      setSuccessModalOpen(false);
      setRecaptchaErrorModalOpen(false);
      setErrorModalOpen(false);
    };
  }, [responseStatus]);

  return (
    <>
      {/* 200 */}
      <ResponseStatusModalTemplate
        title="Successful!"
        description="Schedule request has been successfully submitted."
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        icon={CheckDoubleCircleIcon}
        bottom={
          <Button variant="contained" color="secondary" href={ROUTE.HOME}>
            Back To Homepage
          </Button>
        }
      />
      {/* 400 | 500 */}
      <ResponseStatusModalTemplate
        title="Something went wrong"
        description="An unexpected error has occurred, Please try again later. Contact support if an error persists."
        open={errorModalOpen}
        handleClose={() => setErrorModalOpen(false)}
        icon={WarningDoubleCircleIcon}
        bottom={
          <Button variant="outlined" color="secondary" href={ROUTE.COMPANY}>
            Contact us
          </Button>
        }
      />
      {/* 402 | 403 */}
      <ResponseStatusModalTemplate
        title={
          responseStatus === 402
            ? "Recaptcha not yet available"
            : "Bot detected"
        }
        description={
          "To re-verify, please refresh the page. Contract support if an error persists."
        }
        open={recaptchaErrorModalOpen}
        handleClose={() => setRecaptchaErrorModalOpen(false)}
        icon={ShieldDoubleCircleIcon}
        bottom={
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => window.location.reload()}
              sx={{ mr: "10px" }}
            >
              Try again
            </Button>
            <Button variant="outlined" color="secondary" href={ROUTE.COMPANY}>
              Contact us
            </Button>
          </>
        }
      />
    </>
  );
};

export default ResponseStatusModal;

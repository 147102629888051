import { Box, Typography } from "@mui/material";
import { GowajeeFullIcon } from "src/assets/svgs";

const GoMeetingZoomUsage = () => {
  return (
    <Box
      pt={{ xs: "24px", md: "0" }}
      paddingX="24px"
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto 150px",
        minHeight: "calc(100vh - 64px)",
        position: "relative",
      }}
    >
      <Box mt={10} mb={10}>
        <img src={GowajeeFullIcon} alt="gowajee-logo" height="12px" />
        <Typography variant="h3" fontWeight={"bold"}>
          How to use GoMeeting with Zoom
        </Typography>
      </Box>

      <Box mt={6}>
        <Typography variant="h3" fontWeight={"bold"} mb={2}>
          Adding the App:
        </Typography>
        <Typography>
          GoMeeting Zoom application does not need to be added/installed, The
          Users will be able to use GoMeeting through our website{" "}
          <a style={{ color: "blue" }} href="https://app.gomeeting.ai/">
            app.gomeeting.ai
          </a>
        </Typography>
      </Box>
      <Box mt={6}>
        <Typography variant="h3" fontWeight={"bold"} mb={2}>
          Features:
        </Typography>
        <Typography mt={1}>
          1. <b>Recording Meeting:</b> The bot will automatically record the
          audio of the meeting.
        </Typography>
        <Typography mt={1}>
          2. <b>Meeting Transcription:</b> The bot will automatically transcribe
          the meeting conversation after the meeting ends.
        </Typography>
        <Typography mt={1}>
          3. <b>Meeting Summary:</b> The bot will automatically summarize the
          meeting after it ends.
        </Typography>
      </Box>
      <Box mt={6}>
        <Typography variant="h3" fontWeight={"bold"} mb={2}>
          Usage:
        </Typography>
        <Typography mt={1}>
          1. Register and log in with a Google or Microsoft account through our
          application website app.gomeeting.ai.
        </Typography>
        <Typography mt={1}>
          2. Copy the Zoom meeting URL from the invitation details on Zoom or by
          using the invite link provided by the application and paste it on our
          website.
        </Typography>
        <Box mt={1}>
          <img
            style={{ width: "400px" }}
            src="https://gowajee.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F3654f877-86dc-4413-9de7-690dd31e38ca%2Fa1fc4598-ff5c-4c17-9659-e4000cae124e%2FUntitled.png?table=block&id=fa9b2d59-6539-4368-83db-12440561b6a4&spaceId=3654f877-86dc-4413-9de7-690dd31e38ca&width=2000&userId=&cache=v2"
            alt=""
          />
          <img
            style={{ width: "400px" }}
            src="https://gowajee.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F3654f877-86dc-4413-9de7-690dd31e38ca%2Fd876a107-41c0-445e-91f1-c621dc9fe2fe%2FUntitled.png?table=block&id=3a37e0fd-54e0-48a4-b6e4-ea99c3614308&spaceId=3654f877-86dc-4413-9de7-690dd31e38ca&width=2000&userId=&cache=v2"
            alt=""
          />
        </Box>
        <Typography mt={1}>
          3. Allow the bot to join the Zoom call through the Zoom application
          once the meeting starts.
        </Typography>
        <Box mt={1}>
          <img
            style={{ width: "400px" }}
            src="https://gowajee.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F3654f877-86dc-4413-9de7-690dd31e38ca%2F8542de5f-71ec-4d47-909d-b556d479df42%2FUntitled.png?table=block&id=f38150da-66a2-42b2-89f7-48912fc8ffa2&spaceId=3654f877-86dc-4413-9de7-690dd31e38ca&width=1340&userId=&cache=v2"
            alt=""
          />
          <img
            style={{ width: "400px" }}
            src="https://gowajee.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F3654f877-86dc-4413-9de7-690dd31e38ca%2F17162eca-17d5-4305-8598-3f7c0f84d15c%2FUntitled.png?table=block&id=70f6bba4-2e8e-4c26-8b3d-92d37aaacb5c&spaceId=3654f877-86dc-4413-9de7-690dd31e38ca&width=1690&userId=&cache=v2"
            alt=""
          />
        </Box>
        <Typography mt={1}>
          4. The bot will automatically record the audio during the meeting.
        </Typography>
        <Typography mt={1}>
          5. After the meeting ends, the bot will automatically leave the
          meeting and initiate the analysis process.
        </Typography>
        <Box mt={1}>
          <img
            style={{ maxWidth: "100%" }}
            src="https://gowajee.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F3654f877-86dc-4413-9de7-690dd31e38ca%2F8571b81c-d0b0-438d-8b0c-54efd2e45bb7%2FUntitled.png?table=block&id=7135bfe5-8f32-4461-a13e-bcf90e62c26f&spaceId=3654f877-86dc-4413-9de7-690dd31e38ca&width=2000&userId=&cache=v2"
            alt=""
          />
        </Box>
        <Typography mt={1}>
          6. The analysis process will take approximately 5-20 minutes,
          depending on the meeting duration.
        </Typography>
        <Typography mt={1}>
          7. Once the analysis process is complete, users can access the
          transcription and summary of the meeting on our application website.
        </Typography>
        <Box mt={1}>
          <img
            style={{ maxWidth: "100%" }}
            src="https://gowajee.notion.site/image/https%3A%2F%2Fprod-files-secure.s3.us-west-2.amazonaws.com%2F3654f877-86dc-4413-9de7-690dd31e38ca%2F1688f2c3-7057-42e6-8e10-86f29d091f85%2FUntitled.png?table=block&id=1b4a7b51-9ad0-4946-9c7a-12b135eba13b&spaceId=3654f877-86dc-4413-9de7-690dd31e38ca&width=2000&userId=&cache=v2"
            alt=""
          />
        </Box>
      </Box>
      <Box mt={6}>
        <Typography variant="h3" fontWeight={"bold"} mb={2}>
          Removing the App:
        </Typography>
        <Typography>
          Since GoMeeting does not require users to add an extension to their
          Zoom account, users can prevent the bot from recording and
          transcribing the meeting conversation by denying it access to join the
          meeting.
        </Typography>
      </Box>
    </Box>
  );
};

export default GoMeetingZoomUsage;

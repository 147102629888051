import { Box, Typography } from "@mui/material";

interface Props {
  tag: string;
}

const Tag = ({ tag }: Props) => {
  return (
    <Box
      sx={{
        borderRadius: "16px",
        padding: "4px 8px",
        border: "solid 1px",
        borderColor: "secondary.dark",
        
      }}
    >
      <Typography variant="body2" color="text.secondary" noWrap>
        {tag}
      </Typography>
    </Box>
  );
};

export default Tag;

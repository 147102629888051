import { Box, Typography } from "@mui/material";
import SectionHeader from "src/components/SectionHeader";
import { performance } from "src/constants/performance";
import { accuracies } from "src/constants/accuracies";
import Speed44XImg from "src/assets/images/speed44x.png";
import { OpenAIIcon } from "src/assets/svgs";

const EnterpriseGradeSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "1212px",
        paddingY: { xs: "32px", md: "60px" },
        paddingX: { xs: "24px", md: "0" },
        margin: "0 auto",
      }}
    >
      <SectionHeader
        children1={
          <Box>
            {/* <Typography
              component="span"
              variant="h2"
              sx={{
                lineHeight: "43.2px",
                wordWrap: "break-word",
                background: "-webkit-linear-gradient(#D7D7D7, #212121)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Enterprise-grade
            </Typography> */}
            <Typography
              component="span"
              variant="h2"
              sx={{
                lineHeight: "43.2px",
                wordWrap: "break-word",
              }}
            >
              Enterprise-grade performance
            </Typography>
          </Box>
        }
        children2={
          <Typography color="text.secondary">
            Our Thai voice AI exhibits cutting-edge performance, the culmination
            of extensive research spanning several years, dating back to the
            early days of the deep learning revolution in 2016.
          </Typography>
        }
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "800px",
          bgcolor: "primary.main",
          borderRadius: "16px",
          gap: "32px",
          padding: { xs: "24px", md: "32px" },
          alignSelf: "center",
          mt: { xs: "48px", md: "60px" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <Typography
            variant="h3"
            color="primary.contrastText"
            sx={{ display: "flex", gap: "10px" }}
          >
            Gowajee faster than <img src={OpenAIIcon} alt="open-ai-logo" />
          </Typography>
          <Typography color="text.secondary">
            Significantly faster than comparable model from OpenAI’s Whisper.
          </Typography>
        </Box>
        <Box sx={{ margin: { xs: "0 auto" }, maxWidth: "289px" }}>
          <img src={Speed44XImg} alt="faster-than-open-ai-44x" width="100%" />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "800px",
          bgcolor: "white",
          borderRadius: "16px",
          gap: "48px",
          padding: { xs: "24px", md: "32px" },
          alignSelf: "center",
          mt: { xs: "48px", md: "32px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <Typography variant="h3">
            Industry-leading Thai ASR accuracy
          </Typography>
          <Typography color="text.secondary">
            Beating local competitors and cloud providers like Google Cloud, AWS
            & Microsoft Azure
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          {accuracies.map((acc, _key) => (
            <Box
              key={_key}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                key={acc.name}
                sx={{
                  display: "flex",
                  width: `${((acc.accuracy - 89.5) / 6.3) * 100}%`,
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: { xs: "10px", md: "16px" },
                    alignItems: "center",
                    height: "12px",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "24px",
                      background:
                        acc.name === "Gowajee"
                          ? "linear-gradient(270deg, #000 0%, #C9CAD2 100%)"
                          : "#C9CAD2",
                      width: "100%",
                      height: "12px",
                      position: "relative",
                    }}
                  />
                  <Typography
                    fontSize={
                      acc.name === "Gowajee"
                        ? { xs: "20px", md: "32px" }
                        : { xs: "14px", md: "20px" }
                    }
                    color={
                      acc.name === "Gowajee" ? "text.primary" : "text.secondary"
                    }
                    fontWeight={acc.name === "Gowajee" ? 500 : 400}
                  >
                    {acc.accuracy}%
                  </Typography>
                </Box>
              </Box>
              <Typography fontWeight={acc.name === "Gowajee" ? 500 : 400}>
                {acc.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          maxWidth: "800px",
          width: "100%",
          alignSelf: "center",
          mt: { xs: "48px", md: "60px" },
        }}
      >
        <Typography variant="h3">
          The speech solution for your enterprise
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "24px 32px",
            flexWrap: "wrap",
            mb: { xs: "24px" },
          }}
        >
          {performance.map((p, _key) => (
            <Box
              key={_key}
              sx={{
                display: "flex",
                padding: "24px",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
                bgcolor: "white",
                borderRadius: "16px",
                flex: { xs: "1 1 100%", md: "1 1 calc(50% - 16px)" },
              }}
            >
              <img src={p.icon} alt={`${p.title}-icon`} />
              <Typography variant="h4">{p.title}</Typography>
              <Typography color="text.secondary" textAlign="center">
                {p.describe}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default EnterpriseGradeSection;

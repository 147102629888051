import { Box, Container, Stack, Typography } from "@mui/material";
import BookADemoButton from "src/components/BookADemoButton";
import { useResponsive } from "src/hooks/useResponsive";
import Lottie from "lottie-react";
import waveIconBlackAnimation from "src/assets/lotties/wave-icon-black.json";

interface BookDemoSectionProps {
  size?: "large" | "small";
}

const BookDemoSection = ({ size = "small" }: BookDemoSectionProps) => {
  const { upMd } = useResponsive();
  return (
    <Box sx={{ bgcolor: "primary.main" }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          paddingY: upMd ? (size === "large" ? "200px" : "60px") : "240px",
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
            gap: "32px",
          }}
        >
          <Lottie
            animationData={waveIconBlackAnimation}
            style={{
              width: "80px",
              height: "80px",
            }}
          />
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h2" color="primary.contrastText">
              Discover our speech and
            </Typography>
            <Typography variant="h2" color="primary.contrastText">
              language AI solutions
            </Typography>
          </Box>
          <BookADemoButton color="secondary" />
        </Stack>
      </Container>
    </Box>
  );
};
export default BookDemoSection;

import { Box, Grid } from "@mui/material";
import { ReactNode } from "react";
import { WhiteCurve } from "src/assets/svgs";
import { useResponsive } from "src/hooks/useResponsive";

interface Props {
  children1: ReactNode;
  children2: ReactNode;
}
const SectionHeader = ({ children1, children2 }: Props) => {
  const { upMd } = useResponsive();

  return (
    <Box sx={upMd ? { padding: "0px 32px 0px 32px" } : {}}>
      <Grid container sx={{ padding: "32px 0px 32px 0px" }}>
        <Grid
          item
          xs={12}
          md={6.3}
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            minHeight: upMd ? "162.9px" : "0px",
          }}
        >
          <Box
            sx={{
              zIndex: 1,
              paddingRight: upMd ? "32px" : "0",
            }}
          >
            {children1}
          </Box>
          {upMd && (
            <img
              src={WhiteCurve}
              alt="white-cure"
              width="auto"
              style={{
                position: "absolute",
                top: "50%",
                right: 0,
                zIndex: 0,
                transform: "translate(20%, -50%)",
              }}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={5.7}
          sx={{
            display: "flex",
            alignItems: "center",
            mt: {xs: '32px', md: 0},
            zIndex: 1,
          }}
        >
          {children2}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionHeader;

import { Box, Button, Toolbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import { GowajeeFullIcon, GowajeeFillWhiteIcon } from "src/assets/svgs";
import { navigates } from "src/constants/navigates";
import BookADemoButton from "../BookADemoButton";
import { ROUTE } from "src/constants/router";
import { NavbarProps } from ".";
import GradientDivider from "../GradientDivider";
import NewTag from "../NewTag/NewTag";

const ToolbarDesktop = ({ theme }: NavbarProps) => {
  const { pathname } = useLocation();
  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <a href={ROUTE.HOME}>
        <img
          src={theme === "dark" ? GowajeeFillWhiteIcon : GowajeeFullIcon}
          alt="gowajee-logo"
          height="24px"
        />
      </a>
      <Box sx={{ display: "flex", gap: "24px", alignItems: 'baseline' }}>
        {navigates.map((nav) => (
          <Box key={nav.name}>
            <span>
              <Button
                variant="text"
                key={nav.name}
                href={nav.path}
                target={nav.isExternal ? "_blank" : "_self"}
                // endIcon={nav.isNew && <NewTag />}
                sx={{
                  gap: 1,
                  alignItems: 'baseline',
                  boxShadow: "none",
                  background: "transparent",
                  color: theme === "dark" ? "#F5F5F5" : "#151515",
                  borderRadius: "16px",
                  ":hover": {
                    background: "transparent",
                    color: theme === "dark" ? "#76B0F1" : "#103996"
                  },
                }}
              >
                {nav.name}
                {nav.isNew && <NewTag />}
              </Button>
            </span>
            {pathname === nav.path && (
              <GradientDivider
                sx={{
                  background: "radial-gradient(at center, #C9C9C9, #F5F5F5)",
                }}
              />
            )}
          </Box>
        ))}
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button
          variant="outlined"
          href="https://console.gowajee.ai/"
          target="_blank"
        >
          Console
        </Button>
        <BookADemoButton
          {...{
            sx:
              theme === "dark"
                ? {
                    background: "#FFF",
                    color: "#151515",
                    ":hover": {
                      background: "#F5F5F5 !important",
                    },
                  }
                : {},
          }}
        />
      </Box>
    </Toolbar>
  );
};

export default ToolbarDesktop;

import { Box, Typography } from "@mui/material";

const AboutUs = () => {
  return (
    <Box
      sx={{
        display: "flex",
        paddingTop: { xs: "0", md: "60px" },
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        maxWidth: "590px",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Typography variant="h2">About Us</Typography>
        {/* <Typography
          variant="h2"
          sx={{
            wordWrap: "break-word",
            background: "-webkit-linear-gradient(#D7D7D7, #212121)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            ml: 1,
          }}
        >
          Us
        </Typography> */}
      </Box>
      <Typography
        sx={{
          color: "#84858A",
          lineHeight: "150%",
        }}
      >
        Our mission is to create seamless voice-based interactions across
        platforms and applications. Through cutting-edge AI & ML, our customized
        solutions deliver accurate transcription, voice commands, voice
        generations, and natural language understanding.
      </Typography>
    </Box>
  );
};

export default AboutUs;

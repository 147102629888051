import { Box, IconButton, Stack, Typography } from "@mui/material";
import { BookmarkStarIcon } from "src/assets/svgs";
import BookDemoSection from "src/components/BookDemoSection";
import SectionHeader from "src/components/SectionHeader";
import Tag from "src/components/Tag";
// import TwoLayerCircleForIcon from "src/components/TwoLayerCircleForIcon";
import { researches } from "src/constants/researches";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

const Technology = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1212px",
          paddingY: { xs: "0", md: "60px" },
          paddingX: { xs: "24px", md: "0" },
          margin: "0 auto",
        }}
      >
        <SectionHeader
          children1={
            <Box>
              <Typography variant="h2">Powered by</Typography>
              <Typography variant="h2">cutting-edge research</Typography>
              {/* <Typography
                component="span"
                variant="h2"
                sx={{
                  background: "-webkit-linear-gradient(#D7D7D7, #212121)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  wordWrap: "break-word",
                }}
              >
                cutting-edge research
              </Typography> */}
            </Box>
          }
          children2={
            <Typography variant="body1" color="text.secondary">
              Browse the archive of our recent academic publications on Speech
              and Natural Language Processing at various leading conferences.
            </Typography>
          }
        />
      </Box>

      <Stack
        sx={{
          paddingY: { xs: "24px", md: "32px" },
          gap: "32px",
          maxWidth: "1040px",
          margin: "0 auto",
          paddingX: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            width: "100%",
            borderBottom: "1px solid rgba(132, 133, 138, 0.50)",
            paddingBottom: "8px",
          }}
        >
          <img
            src={BookmarkStarIcon}
            alt="gowajee-bookmark-icon"
            height="24px"
          />
          <Typography variant="body1" color="text.secondary">
            Total {researches.length} research papers
          </Typography>
        </Box>

        {researches.map((research, _key) => (
          <Stack
            key={_key}
            sx={{
              borderBottom: "1px solid rgba(132, 133, 138, 0.50)",
              ":last-child": {
                borderBottom: "0",
              },
            }}
          >
            <Box
              sx={{ display: "flex", gap: "8px", mb: "16px", flexWrap: "wrap" }}
            >
              {research.tags.map((tag, _key) => (
                <Tag tag={tag} />
              ))}
            </Box>
            <Box
              {...(research.link
                ? {
                    component: "a",
                    href: research.link,
                    target: "_blank",
                    sx: { textDecoration: "none", color: "inherit" },
                  }
                : {})}
            >
              <Typography
                component="span"
                variant="h3"
                fontWeight="bold"
                sx={
                  research.link
                    ? { ":hover": { textDecoration: "underline" } }
                    : {}
                }
              >
                {research.title}
              </Typography>{" "}
              {research.link && (
                <IconButton
                  href={research.link}
                  sx={{ padding: 0 }}
                  target="_blank"
                >
                  <OpenInNewRoundedIcon
                    sx={{
                      width: "18px",
                      height: "24px",
                      paddingBottom: "6px",
                      color: "secondary.dark",
                    }}
                  />
                </IconButton>
              )}
            </Box>
            <Typography variant="body1" sx={{ my: "16px" }}>
              {research.description}
            </Typography>
            <Box
              sx={{
                display: "flex",
                mb: { xs: "24px", md: "42px" },
                gap: "16px",
              }}
            >
              <Typography variant="body2">{research.date}</Typography>
              {research.specialNote && (
                <Typography variant="body2" color="text.secondary">
                  • {research.specialNote}
                </Typography>
              )}
            </Box>
          </Stack>
        ))}
      </Stack>

      <BookDemoSection />
    </>
  );
};

export default Technology;

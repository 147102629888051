import { Box, Typography } from "@mui/material";
import { GowajeeFullIcon } from "src/assets/svgs";

const PrivacyPolicy = () => {
  return (
    <Box
      pt={{ xs: "24px", md: "0" }}
      paddingX="24px"
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto 150px",
        minHeight: "calc(100vh - 64px)",
        position: "relative",
      }}
    >
      <Box mt={10} mb={10}>
        <img src={GowajeeFullIcon} alt="gowajee-logo" height="12px" />
        <Typography variant="h3" fontWeight={"bold"}>
          Privacy Policy
        </Typography>
      </Box>

      {/* 1 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          1. Introduction
        </Typography>
        <Typography mt={2}>
          Welcome to Gowajee.ai ("we," "us," "our"). This Privacy Policy
          outlines how we collect, use, disclose, and protect your personal
          information when you use our AI auto speech recognition platform ("the
          Platform"). By accessing or using the Platform, you consent to the
          practices described in this Privacy Policy.
        </Typography>
      </Box>

      {/* 2 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          2. Information We Collect
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>2.1 User-Provided Information:</b> When you create an account or
            use our Platform, we may collect personal information such as your
            name, email address, and other contact details.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>2.2 Audio Data:</b> We do not store your audio data and we just
            process the audio data you submit to the Platform for transcription
            and analysis.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>2.3 Usage Information:</b> We may collect information about how
            you interact with the Platform, such as your IP address, browser
            type, device information, and usage patterns.
          </Typography>
        </Box>
      </Box>

      {/* 3 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          3. How We Use Your Information
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>3.1 Service Provision:</b> We use the information you provide to
            deliver and improve our services.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>3.2 Communication:</b> We may use your contact information to
            send you updates, announcements, and promotional materials related
            to our services. and analysis.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>3.3 Analytics:</b> We may use aggregated and anonymized data for
            analytical purposes to improve the Platform's performance and user
            experience.
          </Typography>
        </Box>
      </Box>

      {/* 4 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          4. Information Sharing
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>4.1 Legal Compliance:</b> We may disclose your information as
            required by law, regulation, or legal process.
          </Typography>
        </Box>
      </Box>

      {/* 5 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          5. Data Security
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>5.1 Security Measures:</b> We implement technical and
            organizational measures to protect your personal information from
            unauthorized access, loss, or misuse.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>5.2 Data Retention:</b> We retain your information only for as
            long as necessary to fulfill the purposes outlined in this Privacy
            Policy and as required by law.
          </Typography>
        </Box>
      </Box>

      {/* 6 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          6. Your Choices
        </Typography>
        <Box mt={2}>
          <Typography>
            <b>6.1 Access and Correction:</b> You can access and update your
            personal information through your account settings on the Platform.
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography>
            <b>6.2 Opt-Out</b> You can opt-out of receiving promotional
            communications from us by following the instructions provided in our
            emails or by contacting us directly.
          </Typography>
        </Box>
      </Box>

      {/* 7 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          7. Children's Privacy
        </Typography>
        <Typography mt={2}>
          Our Platform is not intended for use by individuals under the age of
          18. We do not knowingly collect personal information from minors.
        </Typography>
      </Box>

      {/* 8 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          8. Changes to this Privacy Policy
        </Typography>
        <Typography mt={2}>
          We reserve the right to update this Privacy Policy as our practices
          evolve. We will notify you of any material changes through the
          Platform or by other means.
        </Typography>
      </Box>

      {/* 9 */}
      <Box mt={6}>
        <Typography variant="h4" fontWeight={"bold"}>
          9. Contact Us
        </Typography>
        <Typography mt={2}>
          If you have any questions, concerns, or requests regarding your
          personal information or this Privacy Policy, please contact us at{" "}
          <b>contact@gowajee.ai</b>.
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;

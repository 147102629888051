export const accuracies = [
  {
    name: "Gowajee",
    accuracy: 95.8,
  },
  {
    name: "Amazon Web Sevices",
    accuracy: 93.5,
  },
  {
    name: "Google Cloud Platform",
    accuracy: 93.2,
  },
  {
    name: "Microsoft Azure",
    accuracy: 91.2,
  },
];
